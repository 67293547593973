import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if(this.props.data){
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function(work){
        return <div key={work.company}><h3>{work.company}</h3>
            <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
            <ul>
                {
                    work.description.map((bullet)=>{
                        return <li>{bullet}</li>
                    })
                }
            </ul>
        </div>
      })
    }

    if(!this.props.data){
        return (<></>)
    }
    return (
      <section id="resume">

      <div className="row work">
         <div className="three columns header-col">
            <h1><span>Work</span></h1>
         </div>

         <div className="nine columns main-col">
          {work}
        </div>
      </div>

      <div className="row education">
          <div className="three columns header-col">
              <h1><span>Education</span></h1>
          </div>

          <div className="nine columns main-col">
              <div className="row item">
                  <div className="twelve columns">
                      {education}
                  </div>
              </div>
          </div>
      </div>


          <div className="row work" >
         <div className="three columns header-col">
            <h1><span>Skills</span></h1>
         </div>

         <div className="nine columns main-col">

            <p>{skillmessage}</p>
             <div style={{display:"flex", flexDirection:'row', justifyContent:'center', flexWrap:'wrap'}}>
                 {
                     this.props.data.skills.map((skill)=>{
                         return <div key={skill} style={{padding:5, margin:"5px 14px"}}><em>{skill}</em></div>
                     })
                 }
             </div>
         </div>

      </div>

      <div className="row skills">

          <div className="three columns header-col">
              <h1><span>Interests</span></h1>
          </div>

          <div className="nine columns main-col">
              <ul>
                  {
                      this.props.data.interests.map((interest)=>{
                          return <li>{interest}</li>
                      })
                  }
              </ul>
              </div>
          </div>

   </section>
    );
  }
}

export default Resume;
