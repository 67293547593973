import React, { Component } from 'react';

class Contact extends Component {
  render() {

    if(this.props.data){
      var message = this.props.data.contactmessage;
      var networks= this.props.data.social.map(function(network){
          return <li key={network.name}><a href={network.url} target="_blank" rel="noopener noreferrer"><i className={network.className}></i></a></li>
      })
    }

    return (
        <section id="contact">
            <div className="row">
                <div className="twelve columns main-col" style={{textAlign:'center', color:'white'}}>
                    <h2 style={{textAlign:'center', color:'white', marginBottom:'20px'}}>Get in touch!</h2>
                    <h6 style={{color:'white'}}>{message}</h6>
                    <div className="row">
                        <ul className="social">
                            {networks}
                        </ul>
                    </div>
                </div>
            </div>

        </section>
    );
  }
}

export default Contact;
